import { toast } from 'react-toastify';
import { removeAuthUser } from './localStorage';

export const handleErr = (err) => {
	const resMessage = (err.response && err.response.data && err.response.data.message) || err.message || err.toString();
	return resMessage;
};

export const showSuccessMsg = (msg) => {
	toast.success(msg, { autoClose: 2000 });
};

export const showErrMsg = (msg) => {
	console.log('msg::', msg);
	if (msg === 'Network Error') {
		removeAuthUser();
		window.location.reload(false);
		toast.error('Network Error', { autoClose: 2000 });
	} else {
		toast.error(msg, { autoClose: 2000 });
	}
};

export const navigateTo = (navigate, path) => {
	setTimeout(() => { navigate(path) }, 400);
};

export const downloadCSV = (title, array) => {
	console.log('parramArray::', array);
	const link = document.createElement('a');
	let csv = convertArrayOfObjectsToCSV(array);
	if (csv == null) return;

	const filename = title.toLowerCase() + '.csv';

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', filename);
	link.click();
};

export const slugify = (value) => {
	const replace = value.replaceAll(" ", "-");
    const lowerCaseStr = replace.toLowerCase();
	return lowerCaseStr;
}

export const convertArrayOfObjectsToCSV = (array) => {
	let result;
	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const keys = Object.keys(array[0])

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];

			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
};

export const downloadPDF = (pdf) => {
	const linkSource = `data:application/pdf;base64,${pdf}`;
	const downloadLink = document.createElement("a");
	const fileName = "file.pdf";

	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
};

export const moneySVG = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={'30px'} cursor={'pointer'} height={'30px'} viewBox="0 0 640 512">
			<path d="M535 7.03C544.4-2.343 559.6-2.343 568.1 7.029L632.1 71.02C637.5 75.52 640 81.63 640 87.99C640 94.36 637.5 100.5 632.1 104.1L568.1 168.1C559.6 178.3 544.4 178.3 535 168.1C525.7 159.6 525.7 144.4 535 135L558.1 111.1L384 111.1C370.7 111.1 360 101.2 360 87.99C360 74.74 370.7 63.99 384 63.99L558.1 63.1L535 40.97C525.7 31.6 525.7 16.4 535 7.03V7.03zM104.1 376.1L81.94 400L255.1 399.1C269.3 399.1 279.1 410.7 279.1 423.1C279.1 437.2 269.3 447.1 255.1 447.1L81.95 448L104.1 471C114.3 480.4 114.3 495.6 104.1 504.1C95.6 514.3 80.4 514.3 71.03 504.1L7.029 440.1C2.528 436.5-.0003 430.4 0 423.1C0 417.6 2.529 411.5 7.03 407L71.03 343C80.4 333.7 95.6 333.7 104.1 343C114.3 352.4 114.3 367.6 104.1 376.1H104.1zM95.1 64H337.9C334.1 71.18 332 79.34 332 87.1C332 116.7 355.3 139.1 384 139.1L481.1 139.1C484.4 157.5 494.9 172.5 509.4 181.9C511.1 184.3 513.1 186.6 515.2 188.8C535.5 209.1 568.5 209.1 588.8 188.8L608 169.5V384C608 419.3 579.3 448 544 448H302.1C305.9 440.8 307.1 432.7 307.1 423.1C307.1 395.3 284.7 371.1 255.1 371.1L158.9 372C155.5 354.5 145.1 339.5 130.6 330.1C128.9 327.7 126.9 325.4 124.8 323.2C104.5 302.9 71.54 302.9 51.23 323.2L31.1 342.5V128C31.1 92.65 60.65 64 95.1 64V64zM95.1 192C131.3 192 159.1 163.3 159.1 128H95.1V192zM544 384V320C508.7 320 480 348.7 480 384H544zM319.1 352C373 352 416 309 416 256C416 202.1 373 160 319.1 160C266.1 160 223.1 202.1 223.1 256C223.1 309 266.1 352 319.1 352z"
			/></svg>
	)
};
