// Account
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const REMOVE_LOGGED_IN_USER = 'REMOVE_LOGGED_IN_USER';
export const SET_FORGOT_USER = 'SET_FORGOT_USER';
export const SET_PROFILE_USER = 'SET_PROFILE_USER';

// Read
// export const SET_READ_DATA = 'SET_READ_DATA';
// export const SET_COMMON_DATA = 'SET_COMMON_DATA';

// // Modal
// export const OPEN_CONNECT_WALLET = 'OPEN_CONNECT_WALLET';
// export const CLOSE_CONNECT_WALLET = 'CLOSE_CONNECT_WALLET';
