// import constant
import { SET_LOGGED_IN_USER, REMOVE_LOGGED_IN_USER, SET_FORGOT_USER,SET_PROFILE_USER } from '../constant';
import { setAuthUser, removeAuthUser } from '../../core/helper/localStorage';
import { LOAD_PROFILE } from 'core/services/apiUrl';

const initialState = {
    isLoggedIn: false,
    token: '',
    authUser: '',
    forgotUser : {
        username : '',
        code : ''
    },
    permissions:[]
    // permissions:["department_create" ,"city_edit", "department_view" , "designation_create" , 
    //              "staff_create" ,"staff_edit","staff_view",'item_create','city_create','category_create','sub-category_create',
    //              'end-category_create','discount_create','brand_create', "order_page", "item_edit",
    //              "uom_create","attribute_create","attribute_edit","courier_delete",
    //             "discount-group_create","discount-group_edit","discount-structure_create",
    //             "scheme-discount_delete", "coupon_delete", "default-discount_delete",
    //             "coupon_view","coupon_create",
    //             "segment_create", "brand_create", "model_create",
    //             "manufacturer_delete","map_categories","map_models"
    //         ],
};

const permissions = ["department_create" ,"city_edit",'staff_edit'];

const account = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_LOGGED_IN_USER:
            setAuthUser(action.payload);           
            return {
                ...state,
                ...action.payload,
            }
        case SET_PROFILE_USER:
            return {
                ...state,
                ...action.payload,        
            }
        case REMOVE_LOGGED_IN_USER:
            removeAuthUser();
            return {
                ...state,
                ...action.payload
            }
        case SET_FORGOT_USER:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
};

export default account;
